import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"
import Footer from "../components/Footer"
import AppFeatures from "../components/features/AppFeatures"

const AppPage = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Shopsmart app" pageName="app" />
      <h1 class="sr-only">Shopsmart app. The only wallet you need.</h1>
      <AppFeatures />
      <Footer />
    </Layout>
  )
}

export default AppPage

export const query = graphql`
  query AppPageQuery {
    storyblokEntry(full_slug: { eq: "app" }) {
      content
      name
    }
  }
`
